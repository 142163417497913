import { Outlet } from 'react-router-dom'
import Navbar from '../Navbar'
import './index.scss'
import Footer from '../Footer'

const Layout = () => {
  return (
    <div className='App'>
      <Navbar />
      <div className='page'>
        <Outlet />
      <Footer />
      </div>
    </div>
  )
}

export default Layout