import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faHome,
  faUser,
  faLaptop,
} from '@fortawesome/free-solid-svg-icons';
import {
  faGithub,
  faLinkedin,
  faSkype,
  faWhatsapp,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import LogoS from '../../assets/images/logo-ih.png';
import './index.scss';

const Navbar = () => {
  const phoneNumber = '46707774688';
  const defaultMessage = 'Hello, I would like to connect!';

  const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');
  const encodedPhoneNumber = encodeURIComponent(`00${formattedPhoneNumber}`);
  const encodedMessage = encodeURIComponent(defaultMessage);

  const [isNavActive, setIsNavActive] = useState(false);

  const handleNav = () => {
    setIsNavActive(!isNavActive);
  };

  return (
    <div className='nav-bar'>
      <div className='logo-section'>
      <Link className='logo' to='/'>
        <img src={LogoS} alt='IndaHouse Logo' />
      </Link>
      </div>
      <div>
      <nav className='mainNav'>
        <NavLink exact activeClassName='active' to='/'>
          <FontAwesomeIcon icon={faHome} color='#4d4d4e' />
        </NavLink>
        <NavLink
          exact
          activeClassName='active'
          className='about-link'
          to='/about'
        >
          <FontAwesomeIcon icon={faUser} color='#4d4d4e' />
        </NavLink>
        <NavLink
          exact
          activeClassName='active'
          className='service-link'
          to='/services'
        >
          <FontAwesomeIcon icon={faLaptop} color='#4d4d4e' />
        </NavLink>
        <NavLink
          exact
          activeClassName='active'
          className='contact-link'
          to='/contact'
        >
          <FontAwesomeIcon icon={faEnvelope} color='#4d4d4e' />
        </NavLink>
      </nav>

      <nav id="mobileNav"
        className={`mobileNav ${isNavActive ? 'mobileNav--active' : ''}`}
        onClick={handleNav}>
        <NavLink exact activeClassName='active' to='/'>HOME</NavLink>
        <NavLink exact activeClassName='active' className='about-link' to='/about'>ABOUT</NavLink>
        <NavLink exact activeClassName='active' className='service-link' to='/services'>SERVICES</NavLink>
        <NavLink exact activeClassName='active' className='contact-link' to='/contact'>CONTACT</NavLink>
      </nav>
      </div>
      <button id='hamburger' className={`hamburger ${isNavActive ? 'hamburger--active' : ''}`}
        onClick={handleNav}>
        &#9776;
      </button>

      <ul>
        <li>
          <Link
            to='https://www.linkedin.com/in/indahedvardsson/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FontAwesomeIcon icon={faLinkedin} color='#4d4d4e' />
          </Link>
        </li>
        <li>
          <a
            href={`whatsapp://send?phone=${encodedPhoneNumber}&text=${encodedMessage}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <FontAwesomeIcon icon={faWhatsapp} color='#4d4d4e' />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
