import React, { useEffect, useState } from 'react';
import LogoTitle from '../../assets/images/logo-i.png';
import LogoTitle2 from '../../assets/images/logo-h.png';
import './index.scss'

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['n', 'd', 'a', 'h', 'o', 'u', 's', 'e']

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setLetterClass('text-animate-hover');
        }, 4000);
      
        // Cleanup function
        return () => clearTimeout(timeoutId);
      }, []);

    return (
        <div className='container about-page'>
            <div className='text-zone'>
                <h2>
                    Indahouse Consulting was founded as a powerhouse for Talent 
                    Management with innovation, transparency and efficiency. We are your 
                    total talent solution partner. We provide consulting services to 
                    transform your business by creating talent solutions to fit your 
                    needs today and for the future.
                </h2>
            </div>
            <div className='logo-zone'>
                <h1>
                    {/* <img src={LogoTitle} alt='profile' /> */}
                    <span className={`${letterClass} _11`}>I</span>
                    <span className={`${letterClass} _12`}>n</span>
                    <span className={`${letterClass} _13`}>d</span>
                    <span className={`${letterClass} _14`}>a</span>
                    {/* <img src={LogoTitle2} alt='profile2' /> */}
                    <span className={`${letterClass} _15`}>H</span>
                    <span className={`${letterClass} _16`}>o</span>
                    <span className={`${letterClass} _17`}>u</span>
                    <span className={`${letterClass} _18`}>s</span>
                    <span className={`${letterClass} _19`}>e</span>
                    <span className={`${letterClass} _20`}>.</span>    
                </h1>
            </div>
        </div>
    )
}

export default About;