import React, { useEffect, useState } from 'react';
import LogoTitle from '../../assets/images/logo-i.png';
import LogoTitle2 from '../../assets/images/logo-h.png';
import IndahProfile from '../../assets/images/indah-profile.png';
import './index.scss'

const Consultancy = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['n', 'd', 'a', 'h', 'o', 'u', 's', 'e']

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setLetterClass('text-animate-hover');
        }, 4000);
      
        // Cleanup function
        return () => clearTimeout(timeoutId);
      }, []);

    return (
        <div className='container consultancy-page'>
            <div className='top-text-zone'>
                <div className='text-zone'>
                    <h1 className='logo'>
                        {/* <img src={LogoTitle} alt='profile' /> */}
                    <span className={`${letterClass} _11`}>I</span>
                    <span className={`${letterClass} _12`}>n</span>
                    <span className={`${letterClass} _13`}>d</span>
                    <span className={`${letterClass} _14`}>a</span>
                    {/* <img src={LogoTitle2} alt='profile2' /> */}
                    <span className={`${letterClass} _15`}>H</span>
                    <span className={`${letterClass} _16`}>o</span>
                    <span className={`${letterClass} _17`}>u</span>
                    <span className={`${letterClass} _18`}>s</span>
                    <span className={`${letterClass} _19`}>e</span>
                    <span className={`${letterClass} _20`}>.</span>
                    
                    </h1>
                    <h2>
                        We deliver innovative approaches to transform and modernize existing platforms together with your workforce needs.
                    </h2>
                </div>
                <div className='text-zone-side'>
                    <h3>
                         Total Workforce Planning is the centralized process supply and demand, assessing gaps, and determining target total talent management interventions to ensure that an organization has the right people - with the right skills in the right places at the right time. 
                    </h3>
                </div>
            </div>
            <div className='top-text-zone-indah'>
                <div className='text-zone-indah'>
                    <h3>
                        <strong>Senior Consultant</strong>
                        <br />
                        Indah Hellström is the founder of Indahouse Consulting and has a 
                        background from the staffing industry and global contingent workforce 
                        solutions across all competence areas. 
                        <br /><br />
                        Indah holds a M.Sc in Business Administration from the Business School at 
                        Stockholm University and is certified in Lean. Her previous client projects 
                        include Microsoft, Skype, Tele2 and Spotify.
                        </h3>
                </div>
                    <div className='image-zone'>
                        <img src={IndahProfile} alt='profile' />
                    </div>
            </div>
        </div>
    )
}

export default Consultancy;