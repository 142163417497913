import React from "react";
import './index.scss';

const Footer = () => {
  return (
    <div className="footer">
        <span className="footer-text">© 2023 Indahouse. All rights reserved.</span>      
    </div>
    )
}

export default Footer;
