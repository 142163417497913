import React from 'react';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AnimatedLetters from '../AnimatedLetters'
import LogoTitle from '../../assets/images/logo-i.png';
import LogoTitle2 from '../../assets/images/logo-h.png';
import HomeProfile from '../../assets/images/indah_cherry.png';
import './index.scss';

const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['n', 'd', 'a', 'h', 'o', 'u', 's', 'e']
    const jobArray = [

    ]

    const emailAddress = 'indah@indahouseconsulting.se';

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setLetterClass('text-animate-hover');
        }, 4000);
      
        // Cleanup function
        return () => clearTimeout(timeoutId);
      }, []);

    return (
    <div className='container home-page'>
        <div className='text-zone'>
            <h1>
              {/* <img src={LogoTitle} alt='profile' /> */}
            <span className={`${letterClass} _11`}>I</span>
            <span className={`${letterClass} _12`}>n</span>
            <span className={`${letterClass} _13`}>d</span>
            <span className={`${letterClass} _14`}>a</span>
            {/* <img src={LogoTitle2} alt='profile2' /> */}
            <span className={`${letterClass} _15`}>H</span>
            <span className={`${letterClass} _16`}>o</span>
            <span className={`${letterClass} _17`}>u</span>
            <span className={`${letterClass} _18`}>s</span>
            <span className={`${letterClass} _19`}>e</span>
            <span className={`${letterClass} _20`}>.</span>
            
            </h1>
            <h2>
              We provide consulting services to transform your business by creating 
              talent solutions to fit your needs today and for the future.</h2>
              <Link to={`mailto:${emailAddress}`} className='flat-button'>CONTACT</Link>
          </div>
        <div className='image-zone'>
            <img src={HomeProfile} alt='profile' />
        </div>
    </div>
    )
}

export default Home;