import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About';
import Consultancy from './components/Consultancy';
import Footer from './components/Footer';
import Contact from './components/Contact';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    document.title = 'IndaHouse';
  }, []);
  
  return (
    <>
    <Routes>
      <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Consultancy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<h1>Hm, this page doesn't seem to exist...</h1>} />
      </Route>
    </Routes>
    <Footer />
    </>
  )
}

export default App;
